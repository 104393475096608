<template lang="pug">
div(v-if="preview || disabled || (dataKey=='referencielTvaId' && (line.type==14 ||line.type==15 ||line.type==16 ||line.type==17 ||line.type==18))")
  div.content-span-document-detail
    span.mr-2 {{ selected(line[dataKey]) }}
div(v-else)
  select.form-select.custom-select(@focus="emitEditFocus(true)" v-model="line[dataKey]" :class="[edit && canEdit ? 'edit' : 'no-edit', line.isDisabled  && dataKey!=='situationProgressQuantity' && dataKey!=='situationProgress' ? 'disabled' :'']" aria-label='Default select example' :disabled="(dataKey == 'referencielTvaId' && (documentOptions.isReverseCharge || !canEdit || line.isDisabled)) || (line.isDisabled && dataKey!=='situationProgressQuantity' && dataKey!=='situationProgress')" @change="change(dataKey)")
    option(v-for='(item, index) in invoiceHeader[dataKey].choice' :value="item.id")  {{ item.label }}
</template>

<script>
import { applyDeduction } from "@/types/api-orisis/library/DeductionOperations.ts";
import { referencielTvaTranslate } from "@/types/api-orisis/library/TranslateOperations.ts";
import { mapActions } from "vuex";

export default {
  props: {
    preview: {
      default: false,
    },
    edit: {
      type: Boolean,
      required: true,
    },
    canEdit: {
      type: Boolean,
      default: true,
      required: true,
    },
    line: {
      type: Object,
      required: true,
    },
    invoiceHeader: {
      type: Object,
      required: false,
    },
    dataKey: {
      type: String,
      required: true,
    },
    editFocus: {
      type: Boolean,
      required: true,
    },
    mouseOn: {
      type: Boolean,
    },
    disabled: {
      default: false,
    },
  },
  computed: {
    document: {
      get() {
        return this.$store.getters.document;
      },
      set(value) {
        return this.$store.commit("SET_DOCUMENT", value);
      },
    },
    documentDetails: {
      get() {
        return this.$store.getters["getDocumentDetails"];
      },
      set(value) {
        return this.$store.commit("SET_DOCUMENT_DETAILS", value);
      },
    },
    documentOptions: {
      get() {
        if (this.document.nature == 0) {
          return this.$store.getters.quoteOption;
        } else if (
          this.document.nature == 1 ||
          this.document.nature == 2 ||
          this.document.nature == 3 ||
          this.document.nature == 4
        ) {
          return this.$store.getters.invoiceOption;
        } else if (this.document.nature == 5 || this.document.nature == 6) {
          return this.$store.getters.creditOption;
        }
      },
      set(value) {
        if (this.document.nature == 0) {
          return this.$store.commit("SET_QUOTE_OPTION", value);
        } else if (
          this.document.nature == 1 ||
          this.document.nature == 2 ||
          this.document.nature == 3 ||
          this.document.nature == 4
        ) {
          return this.$store.commit("SET_INVOICE_OPTION", value);
        } else if (this.document.nature == 5 || this.document.nature == 6) {
          return this.$store.commit("SET_CREDIT_OPTION", value);
        }
      },
    },
  },
  methods: {
    ...mapActions([
      "updateDocumentDetail",
      "loopDocumentDetails",
      "updateTvaDocumentDetail",
      "updateQuote",
      "updateInvoice",
      "updateCredit",
      "updateCreditDetail",
    ]),
    referencielTvaTranslate,
    applyDeduction,
    selected(id) {
      if (this.invoiceHeader[this.dataKey].choice.find((el) => el.id == id)) {
        return this.invoiceHeader[this.dataKey].choice.find((el) => el.id == id)
          .label;
      } else {
        return "--";
      }
    },
    blurFunction(res) {
      this.emitEditFocus(res);
      this.document.nature == 5 || this.document.nature == 6
        ? this.updateCreditDetail({ creditDetail: this.line })
        : this.updateDocumentDetail({ documentDetail: this.line });
    },
    emitEditFocus(res) {
      this.$emit("emitEditFocus", res);
    },
    change(dataKey) {
      if (dataKey == "referencielTvaId") {
        this.changeAllReferencielTva();
      } else {
        this.blurFunction(false);
      }
    },
    changeAllReferencielTva() {
      let documentDetailIds = [];
      // Autoliquidation
      if (
        this.dataKey == "referencielTvaId" &&
        this.line[this.dataKey] == 5 &&
        this.line.type == 6
      ) {
        this.$bvModal
          .msgBoxConfirm("Est-ce une autoliquidation ?", {
            title: "TVA à 0% détectée",
            size: "sm",
            okVariant: "primary",
            okTitle: "Oui, appliquer à toutes les lignes",
            cancelTitle: "Non",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          })
          .then((value) => {
            if (value) {
              this.documentOptions.isReverseCharge = true;
              this.loopDocumentDetails({ referencielTvaId: 5 }).then(() => {
                this.saveAction(false);
              });
            } else {
              documentDetailIds.push(this.line.id);
              let workLineElements = this.documentDetails.filter(
                (el) => el.parentId == this.line.id
              );
              for (let index = 0; index < workLineElements.length; index++) {
                const element = workLineElements[index];
                element.referencielTvaId = this.line.referencielTvaId;
                documentDetailIds.push(element.id);
              }
              this.updateTvaDocumentDetail({
                documentDetailIds: documentDetailIds,
                referencielTvaId: 5,
              });
              this.applyDeduction();
            }
          });
      } else if (
        this.dataKey == "referencielTvaId" &&
        this.line[this.dataKey] == 5
      ) {
        this.$bvModal
          .msgBoxConfirm("Est-ce une autoliquidation ?", {
            title: "TVA à 0% détectée",
            size: "sm",
            okVariant: "primary",
            okTitle: "Oui, appliquer à toutes les lignes",
            cancelTitle: "Non",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          })
          .then((value) => {
            if (value) {
              this.documentOptions.isReverseCharge = true;
              this.loopDocumentDetails({ referencielTvaId: 5 }).then(() => {
                this.saveAction(false);
              });
            } else {
              this.document.nature == 5 || this.document.nature == 6
                ? this.updateCreditDetail({ creditDetail: this.line })
                : this.updateDocumentDetail({ documentDetail: this.line });
              this.applyDeduction();
            }
          });
      } else if (
        this.dataKey == "referencielTvaId" &&
        this.line[this.dataKey] != 5 &&
        this.line.type == 6
      ) {
        this.$bvModal
          .msgBoxConfirm(
            "Voulez-vous appliquer cette TVA à toutes les autres lignes ?",
            {
              title:
                "TVA à " +
                referencielTvaTranslate(this.line[this.dataKey]) +
                " détectée",
              size: "sm",
              okVariant: "primary",
              okTitle: "Oui, appliquer à toutes les lignes",
              cancelTitle: "Non",
              cancelVariant: "outline-primary",
              hideHeaderClose: true,
              centered: true,
            }
          )
          .then((value) => {
            if (value) {
              this.loopDocumentDetails({
                referencielTvaId: this.line.referencielTvaId,
              }).then(() => {
                this.saveAction(false);
              });
            } else {
              documentDetailIds.push(this.line.id);
              let workLineElements = this.documentDetails.filter(
                (el) => el.parentId == this.line.id
              );
              for (let index = 0; index < workLineElements.length; index++) {
                const element = workLineElements[index];
                element.referencielTvaId = this.line.referencielTvaId;
                documentDetailIds.push(element.id);
              }
              this.updateTvaDocumentDetail({
                documentDetailIds: documentDetailIds,
                referencielTvaId: this.line.referencielTvaId,
              });
              this.applyDeduction();
            }
          });
      } else if (
        this.dataKey == "referencielTvaId" &&
        this.line[this.dataKey] != 5
      ) {
        this.$bvModal
          .msgBoxConfirm(
            "Voulez-vous appliquer cette TVA à toutes les autres lignes ?",
            {
              title:
                "TVA à " +
                referencielTvaTranslate(this.line[this.dataKey]) +
                " détectée",
              size: "sm",
              okVariant: "primary",
              okTitle: "Oui, seulement aux existantes",
              cancelTitle: "Non",
              cancelVariant: "outline-primary",
              hideHeaderClose: true,
              centered: true,
            }
          )
          .then((value) => {
            if (value) {
              this.loopDocumentDetails({
                referencielTvaId: this.line[this.dataKey],
              }).then(() => {
                this.saveAction(false);
              });
            } else {
              this.document.nature == 5 || this.document.nature == 6
                ? this.updateCreditDetail({ creditDetail: this.line })
                : this.updateDocumentDetail({ documentDetail: this.line });
              this.applyDeduction();
            }
          });
      }
    },
    saveAction(bool) {
      this.applyDeduction();
      if (this.document.nature == 0) {
        this.updateQuote({ quote: this.document, updateState: bool });
      } else if (
        this.document.nature == 1 ||
        this.document.nature == 2 ||
        this.document.nature == 3 ||
        this.document.nature == 4
      ) {
        this.updateInvoice({ invoice: this.document, updateState: bool });
      } else {
        this.updateCredit({ credit: this.document, updateState: bool });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<template lang="pug">
div.d-flex.justify-center(:ref="'container'" tabindex='0')
    b-input-group.p-0.m-0.custom-control-inline
        b-input-group-prepend
            b-button.p-0.m-0(variant='warning btn-sm' @click="quantity--")
                span.material-icons-outlined remove
        b-form-input.text-center(:ref="'containerInput'" min="0" type='number' pattern="\d+" v-model='quantity'  )
        b-input-group-append
            b-button.p-0.m-0(variant='primary btn-sm' @click="quantity++")
                span.material-icons-outlined add
</template>
<script>
export default {
 data() {
    return {
      quantity: 0,
    };
  },
  methods: {
    getValue() {
        return this.quantity
    },
    
    isCancelBeforeStart() {
      return false;
    },
  },
  mounted() {
    this.quantity = this.params.value;
    this.$nextTick(() => {
      this.$refs.containerInput.focus();
    });
  },
};
</script>

<template lang="pug">
table.documentDetails   
  thead
    InventoryPreviewHeader()
  tbody 
    InventoryPreviewDetails(v-for="(line, index) in inventoryDetailsFormated" :key="index" :line="line" :index="index")
</template>
<script>
import InventoryPreviewHeader from '@/components/purchase/inventory/preview/table/InventoryPreviewHeader.vue'
import InventoryPreviewDetails from '@/components/purchase/inventory/preview/table/InventoryPreviewDetails.vue'
import draggable from 'vuedraggable'
import { mapGetters, mapActions } from 'vuex'

export default {
  data() {
    return {
      dataFormProducts:[],
    };
  },
  computed: {
    ...mapGetters([
      'inventory',
      'inventoryDetailsFormated'
    ])
  },
  components: {
    InventoryPreviewHeader,
    InventoryPreviewDetails,
    draggable
  }
}
</script>

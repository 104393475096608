<template lang="pug">
div
  .content-header
    .content-header-search.d-flex
      b-input-group.w-50
        .position-relative.w-100.d-flex.align-items-center
          b-button.p-0.position-absolute.btn-gray.br.border-right-0(variant='transparent' style='left: 0.5rem')
            span.material-icons-outlined.text-secondary search
          b-form-input#searchInventory.w-full.pl-4(v-model='searchProduct' placeholder='Rechercher un produit' v-on:input='onFilterTextBoxChanged()' style='border-top-right-radius: 0.357rem; border-bottom-right-radius: 0.357rem;')
          b-button.p-0.position-absolute.btn-gray.hover(variant='transparent' :class="this.gridOptions.floatingFiltersHeight > 0 ? 'active' : ''" style='right: 10px' @click='showRowFilter()')
            span.material-icons-outlined(:class="this.gridOptions.floatingFiltersHeight > 0 ? 'text-primary active' : 'text-secondary'") filter_alt
      v-select.w-50.ml-1(placeholder="Filter par fournisseur"  :loading='isLoadingCompaniesList' :deselectFromDropdown='true' :closeOnSelect='false' :multiple="true" v-model='providerIdsFilter' :options="providersList" :reduce='elem => elem.id' label="name")
        template(v-slot:no-options='')
          template  Aucun r&eacute;sultat trouv&eacute;
  .content-grid.d-flex.w-100(style="height: 250px;")
    .text-center.flex-center.w-100(v-if='isLoadingInventoryDetails')
      .loading-bg-inner
        .loader
          .outer
          .middle
          .inner
      .mt-5
        br
        br
        br
        |           Chargement de la liste des produits en cours...
    .row.m-0.w-100(v-else)
      ag-grid-vue.mt-1.ag-theme-material.ag-primary-header(style='width: 100%; height:100%; border: 1px solid #e9ecef;' :rowData='inventoryDetailsFormatedComputed' :gridOptions='gridOptions' :animateRows='true' :enablecellChangeFlash='true' @grid-ready='onGridReady' @cell-value-changed="onCellValueChanged")
      ejs-sidebar.default-sidebar.items-no-padding(v-show="isOpen" ref="sidebar" :type="type" :position="position" :showBackdrop="showBackdrop" :enablePersistence="enablePersistence"  :closeOnDocumentClick="closeOnDocumentClick" :isOpen="isOpen")
        sidebar-product-content(ref="sidebarProductContent" @closeSidebar="closeSidebar")
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { AgGridVue } from "ag-grid-vue";
import { agGridLocaleFR } from "@/data/agGridLocaleFR";
import vSelect from "vue-select";

import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";
import { productTypeTranslate } from "@/types/api-orisis/library/TranslateOperations.ts";
import CellEditorQuantity from "@/components/purchase/inventory/CellEditorQuantity";
import CellRendererAction from "@/components/purchase/inventory/builder/CellRendererAction";

import SidebarProductContent from "@/components/catalog/SidebarProductContent.vue";
import { SidebarComponent } from "@syncfusion/ej2-vue-navigations";

export default {
  data() {
    return {
      isOpen: false,
      position: "Right",
      type: "Push",
      enablePersistence: false,
      closeOnDocumentClick: true,
      showBackdrop: true,
      dataOrigine: {},
      inventoryForm: {},

      gridOptions: {
        floatingFiltersHeight: 0,
        localeText: agGridLocaleFR,
        columnDefs: null,
        defaultColDef: {
          flex: 1,
          minWidth: 100,
          editable: false,
        },
        enableRangeSelection: true,
        pagination: true,
        paginationPageSize: 50,
        enableCellChangeFlash: "true",
        suppressScrollOnNewData: "true",
      },
      gridApi: null,
      columnApi: null,
      searchProduct: "",
      providerIdsFilter: [],
    };
  },
  computed: {
    ...mapGetters([
      "inventory",
      "inventoryDetailsFormated",
      "isLoadingInventory",
      "isLoadingCatalog",
      "isLoadingInventoryDetails",
      "companiesList",
      "isLoadingCompaniesList",
    ]),
    inventoryDetails: {
      get() {
        return this.$store.getters["inventoryDetails"];
      },
      set(value) {
        return this.$store.commit("SET_INVENTORY_DETAILS", value);
      },
    },
    providersList() {
      return this.companiesList.filter((elem) => elem.companyType?.id == 4);
    },
    inventoryDetailsFormatedComputed() {
      if (this.providerIdsFilter.length > 0) {
        return this.inventoryDetailsFormated.filter((product) => {
          return this.verifierElements(
            this.providerIdsFilter,
            product.providerIds
          );
        });
      } else {
        return this.inventoryDetailsFormated;
      }
    },
  },
  created() {
    if (this.companiesList.length == 0) this.getCompanies({});
  },
  beforeMount() {
    this.gridOptions.columnDefs = [
      {
        field: "reference",
        headerName: "Référence",
        filter: "agTextColumnFilter",
        floatingFilter: true,
        resizable: true,
        lockVisible: true,
        sortable: true,
        minWidth: 160,
        maxWidth: 180,
      },
      {
        headerName: "Type",
        field: "productType",
        filter: true,
        floatingFilter: true,
        initialHide: true,
        cellRenderer: (params) => {
          return productTypeTranslate(params.value);
        },
        resizable: true,
        lockVisible: true,
        sortable: true,
        minWidth: 160,
        maxWidth: 180,
      },
      {
        field: "label",
        headerName: "Libellé de recherche",
        filter: "agTextColumnFilter",
        floatingFilter: true,
        initialHide: true,
        resizable: true,
        lockVisible: true,
        sortable: true,
        minWidth: 20,
      },
      {
        headerName: "Désignation",
        field: "description",
        filter: "agTextColumnFilter",
        floatingFilter: true,
        resizable: true,
        lockVisible: true,
        sortable: true,
        minWidth: 118,
        cellRenderer: (params) => {
          if (params.value) {
            return params.value.replace(/<[^>]+>/g, "");
          }
        },
      },
      {
        headerName: "Stock",
        field: "stock",
        filter: "agNumberColumnFilter",
        floatingFilter: true,
        resizable: true,
        sortable: true,
        minWidth: 120,
        maxWidth: 220,
        editable: false,
        headerClass: "text-center",
        cellStyle: { textAlign: "center" },
      },
      {
        headerName: "Quantité relevée",
        field: "quantityNoted",
        type: "numericColumn",
        filter: "agNumberColumnFilter",
        floatingFilter: true,
        editable: true,
        sortable: true,
        minWidth: 120,
        maxWidth: 220,
        cellEditor: "CellEditorQuantity",
        cellEditorPopup: false,
        editable: true,
        headerClass: "text-center",
        cellClass: ["ag-cell--editable"],
        cellStyle: (params) => {
          if (!params.value && params.value !== 0) {
            return { backgroundColor: "#eef1f6", textAlign: "center" };
          } else if (params.value == params.data.stock) {
            return { backgroundColor: "#28a74550", textAlign: "center" };
          } else if (params.value >= params.data.stock) {
            return { backgroundColor: "#ff9f4350", textAlign: "center" };
          } else if (
            params.value < params.data.stock &&
            params.value !== null
          ) {
            return { backgroundColor: "#dc354550", textAlign: "center" };
          }
          return { backgroundColor: "#eef1f6", textAlign: "center" };
        },
      },
      {
        headerName: "Unité",
        field: "unit.label",
        filter: "agTextColumnFilter",
        floatingFilter: true,
        resizable: true,
        sortable: true,
        minWidth: 80,
        maxWidth: 120,
      },
      {
        headerName: "",
        field: "id",
        minWidth: 40,
        maxWidth: 60,
        cellRenderer: "CellRendererAction",
      },
    ];
  },
  methods: {
    ...mapActions([
      "createInventoryDetail",
      "updateInventoryDetail",
      "getCompanies",
    ]),
    formatCurrency,
    productTypeTranslate,
    verifierElements(tableau1, tableau2) {
      const ensemble2 = new Set(tableau2);
      for (let i = 0; i < tableau1.length; i++) {
        if (ensemble2.has(tableau1[i])) {
          return true;
        }
      }
      return false;
    },

    autoSizeAll(skipHeader) {
      const allColumnIds = [];
      this.gridApi.getColumnDefs().forEach((column) => {
        allColumnIds.push(column.field);
      });
      this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.gridApi.setHeaderHeight(36);
    },
    onRowClicked(event) {
      this.isOpen = true;
      this.$refs["sidebarProductContent"].getProductDetails(event.data.id);
      this.$refs.sidebar.toggle();
    },
    async onCellValueChanged(params) {
      if (params.newValue || params.newValue == 0) {
        if (!params.data.inventoryDetail) {
          // On créé la ligne d'inventaire inventoryDetails
          let inventoryDetails = {
            id: 0,
            index: 0,
            reference: params.data.reference,
            description: params.data.description,
            quantity: params.newValue,
            unitId: params.data.unit ? params.data.unit.id : null,
            productId: params.data.id,
            inventoryId: this.inventory.id,
          };
          await this.createInventoryDetail({
            inventoryDetail: inventoryDetails
          }).then((res) => {
            let dataProduct = {
              ...params.data,
              inventoryDetailId: res.id,
              inventoryDetail: res,
            };
            this.inventoryDetailsFormated.splice(
              this.inventoryDetailsFormated.findIndex(
                (product) => product.id == params.data.id
              ),
              1,
              dataProduct
            );
          });
        } else {
          // On update le ligne d'inventaire
          let inventoryDetails = {
            id: params.data.inventoryDetailId,
            index: 0,
            reference: params.data.reference,
            description: params.data.description,
            quantity: params.newValue,
            unitId: params.data.unit ? params.data.unit.id : null,
            productId: params.data.id,
            inventoryId: this.inventory.id,
          };
          await this.updateInventoryDetail(inventoryDetails).then((res) => {
            let dataProduct = {
              ...params.data,
              inventoryDetailId: res.id,
              inventoryDetail: res,
            };
            this.inventoryDetailsFormated.splice(
              this.inventoryDetailsFormated.findIndex(
                (product) => product.id == params.data.id
              ),
              1,
              dataProduct
            );
          });
        }
      }
    },
    onFilterTextBoxChanged() {
      this.gridApi.setQuickFilter(this.searchProduct);
    },
    showRowFilter() {
      if (this.gridOptions.floatingFiltersHeight > 0) {
        this.gridApi.setFloatingFiltersHeight(0);
        this.gridApi.setFilterModel(null);
        this.gridApi.onFilterChanged();
      } else {
        this.gridApi.setFloatingFiltersHeight(36);
      }
    },
    togglePanelColumn() {
      if (!this.gridOptions.sideBar || this.gridOptions.sideBar == null) {
        this.gridOptions.sideBar = {
          toolPanels: [
            {
              id: "columns",
              labelDefault: "Colonnes",
              labelKey: "columns",
              iconKey: "columns",
              toolPanel: "agColumnsToolPanel",
              toolPanelParams: {
                suppressRowGroups: true,
                suppressValues: true,
                suppressPivots: true,
                suppressPivotMode: true,
                suppressColumnFilter: true,
                suppressColumnSelectAll: true,
                suppressColumnExpandAll: true,
              },
            },
          ],
          defaultToolPanel: "columns",
        };
        this.filter = true;
      } else {
        this.gridOptions.sideBar = null;
        this.filter = false;
      }
    },
    exportDataXLS() {
      this.gridApi.exportDataAsExcel();
    },
    exportDataCSV() {
      this.gridApi.exportDataAsCsv();
    },
    closeSidebar() {
      this.$refs.sidebar.hide();
    },
  },
  components: {
    AgGridVue,
    CellEditorQuantity,
    CellRendererAction,
    SidebarProductContent,
    vSelect,
    "ejs-sidebar": SidebarComponent,
  },
};
</script>
<style lang="scss">
.ag-primary-header {
  .ag-header-viewport {
    background: #0c3571;
  }
  .ag-header-cell-text {
    font-size: 14px !important;
    font-weight: 400 !important;
    color: #fff;
  }
}
</style>

<template lang="pug">
div(v-if="isLoadingInventory|| isUpdatingInventoryDetail || isValidatingInventory")
  .text-center.flex-center.h-100
      .loading-bg-inner
        .loader
          .outer
          .middle
          .inner
      .mt-5
        br
        br
        br
        |  {{ isValidatingInventory ? "Clôture de l'inventaire en cours, veuillez patienter..." : "Chargement de l'inventaire en cours..." }} 
.builder(v-else)
  .builder-area
    InventoryHeader()
    InventoryTable()
</template>

<script>
import InventoryHeader from "@/components/purchase/inventory/builder/InventoryHeader.vue";
import InventoryTable from "@/components/purchase/inventory/builder/InventoryTable.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    InventoryHeader,
    InventoryTable,
  },
  computed: {
    ...mapGetters([
      "institution",
      "isLoadingInventory",
      "isUpdatingInventoryDetail",
      "isValidatingInventory",
    ]),
    inventory: {
      get() {
        return this.$store.getters.inventory;
      },
      set(value) {
        return this.$store.commit("SET_INVENTORY", value);
      },
    },
    inventoryDetails: {
      get() {
        return this.$store.getters.inventoryDetails;
      },
      set(value) {
        return this.$store.commit("SET_INVENTORY_DETAILS", value);
      },
    },
  },
  methods: {
    ...mapActions(["updateInventory"]),
    saveAction(bool) {
      this.updateInventory({ inventory: this.inventory, updateState: bool });
    },
  },
};
</script>
<style scoped>
#invoiceContainer {
  height: calc(100% - 23px) !important;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}
.builder-area {
  margin-top: 1rem;
  padding: 20px;
  background-color: white !important;
  border: solid 2px #f1f1f1;
}
</style>

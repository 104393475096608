<template lang="pug">
div 
  b-row
    b-col(cols="12").col-lg-6
      ul
        li 
          h3.text-primary Inventaire n° {{ inventory.documentReference ?  inventory.documentReference : 'en attente' }} 
            span(style='font-weight:300')
        li.d-flex.align-items-center
          Status(:statusId="inventory.status" nature="inventoryForm" :static="true")
          SelectDate(keyValue="inventoryDate" @setValue="setInventoryDate" documentDetailKey="inventoryDate" text="En date du ")
        li(v-if="!inventory.hideUser").mt-2
          UserInfoDocument 
    b-col(cols="12").offset-lg-1.col-lg-5
      div
      SelectStorageLocation(@setValue="setSelectStorageLocation" :placeholder="'Sélectionner un point de stockage'" :options='storageLocationsList')
  div
    div.py-1(class="align-items-center")
      InventoryDescription(@setValue="setDescription" :key="'Description_'+inventory.id")
    div.pb-50(class="align-items-center")
      span.text-primary.font-weight-bold.no-printting Rappel des articles 
</template>
<script>
import vSelect from "vue-select";
import { mapGetters, mapActions } from "vuex";

import {
  natureTranslate,
  natureSimplifiedTranslate,
} from "@/types/api-orisis/library/TranslateOperations.ts";

import Status from "@/components/global/status/Status";
import SelectDate from "@/components/purchase/inventory/builder/SelectDate";
import UserInfoDocument from "@/components/purchase/inventory/builder/UserInfoDocument";
import SelectStorageLocation from "@/components/purchase/inventory/builder/SelectStorageLocation";
import InventoryDescription from "@/components/purchase/inventory/builder/InventoryDescription";

export default {
  data() {
    return {
      collaboratorInfo: null,
    };
  },
  computed: {
    ...mapGetters(["storageLocationsList", "affairsList", "natureList"]),
    inventory: {
      get() {
        return this.$store.getters.inventory;
      },
      set(value) {
        return this.$store.commit("SET_INVENTORY", value);
      },
    },
  },
  created() {
    if (!this.storageLocationsList || this.storageLocationsList.length == 0) {
      this.getStorageLocations({});
    }
    if (!this.collaboratorsList || this.collaboratorsList.length == 0) {
      this.getCollaborators({});
    }
  },
  methods: {
    ...mapActions([
      "updateInventory",
      "getStorageLocations",
      "getCollaborators",
    ]),
    natureTranslate,
    natureSimplifiedTranslate,

    setInventoryDate(date) {
      this.inventory = {
        ...this.inventory,
        inventoryDate: date,
      };
      this.saveAction();
    },
    async setSelectStorageLocation(storageLocation) {
      this.inventory = {
        ...this.inventory,
        storageLocationId: storageLocation.id ? storageLocation.id : null,
        storageLocation: storageLocation,
      };
      this.saveAction();
    },
    saveAction() {
      this.updateInventory({ inventory: this.inventory, updateState: false });
    },
    setDescription(description) {
      this.inventory = {
        ...this.inventory,
        description: description,
      };
      this.saveAction();
    },
  },
  components: {
    "v-select": vSelect,
    Status,
    SelectDate,
    UserInfoDocument,
    SelectStorageLocation,
    InventoryDescription,
  },
};
</script>
<style scoped>
.align-items-center {
  align-items: center;
}

.quote-card {
  margin-bottom: 10px;
}

ul li {
  list-style: none;
}

.bold {
  font-weight: bold;
}

a {
  color: #0c3571 !important;
  text-decoration: underline !important;
}
</style>

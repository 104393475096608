<template lang="pug">
.d-flex.h-100.w-100.align-items-center
  span.handle.cursor-pointer(class='material-icons' @click="showProductDetails()") more_horiz
</template>
<script>
export default {
  methods: {
    showProductDetails() {
      this.$parent.$parent.isOpen = true;
      if (!this.$parent.$parent.archive) {
        this.$parent.$parent.$refs["sidebarProductContent"].getProductDetails(this.params.value, true);
      } else {
        this.$parent.$parent.$refs["sidebarProductContent"].getProductArchivedDetails(
          this.params.value,
          this.archive,
          true
        );
      }
      this.$parent.$parent.$refs.sidebar.toggle();
    },
  }
};
</script>

<template lang="pug">
tr
  td(v-for="(key, i) in Object.keys(inventoryHeader)" :key="i" v-if="inventoryHeader[key].display" :class="inventoryHeader[key].display ? inventoryHeader[key].class : ''" :style="'background:'+inventory.institutionColor") 
    .header {{inventoryHeader[key].text}}    
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters(['inventory']),
    inventoryHeader: {
      get() {
        return this.$store.getters.getInventoryHeader;
      },
      set(val) {
        this.$store.commit("setInventoryHeader", val);
      }
    }
  },
};
</script>
<template lang="pug">
.cursor-pointer(v-if='!preview' @mouseover='mouseOn = true' @mouseleave='mouseOn = false')
  div(@click='toggleDescription(!inventory.showDescription)')
    span.text-primary.font-weight-bold.no-printting
      | {{ inventory.showDescription == true  ? &quot;- Masquer la description&quot; : &quot;+ Ajouter une description&quot; }}
  quill-editor.editor.quill-object(@focus='focusFunction(true)' @blur='setValue' :key="'description' + inventory.id" v-if='inventory.showDescription' :class="editFocus || mouseOn ? 'edit' : 'no-edit'" v-model='inventory.description' :options='editorOption')
div(v-else-if="inventory.showDescription")
  span(v-html='inventory.description')
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    preview: {
      default: false,
    },
  },
  data() {
    return {
      mouseOn: false,
      editorOption: {
        placeholder: "",
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            ["blockquote"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }],
            [{ align: [] }],
          ],
        },
      },
      editFocus: false,
    };
  },
  computed: {
    inventory: {
      get() {
        return this.$store.getters.inventory;
      },
      set(value) {
        return this.$store.commit("SET_INVENTORY", value);
      },
    },
  },
  methods: {
    ...mapActions(["updateInventory"]),
    focusFunction(val) {
      this.editFocus = true;
    },
    setValue() {
      this.$emit("setValue", this.inventory.description);
      this.editFocus = false;
    },
    toggleDescription(val) {
      this.inventory.showDescription = val;
      this.updateInventory({ inventory: this.inventory, updateState: false });
    },
  },
  components: {
    quillEditor,
  },
};
</script>

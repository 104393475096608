<template lang="pug">
#invoiceContainer(style="height: calc(100% - 43px); overflow: hidden;" v-if="this.$route.fullPath.includes(inventory.id)")
  .content-loader-center.m-0(v-if='isLoadingInventory')
    .text-center.flex-center
      .loading-bg-inner(role='status')
        .loader
          .outer
          .middle
          .inner
      br
      | Chargement de l'inventaire en cours...
  div.h-100(v-else)
    InventoryBuilderHeader(:key="id" @changePreviewKey="changePreviewKey" )
    InventoryBuilder(v-if="inventoryTabActive == 'inventory-edit'")
    .content-tab-fullpage(v-else-if="inventoryTabActive == 'inventory-pdf'" :key="previewKey")
      InventoryPdf  
    .content-tab-fullpage(v-else-if="inventoryTabActive == 'internal-note'")
      InternalNote
    .content-tab-fullpage(v-else-if="inventoryTabActive == 'attachments'")
      ged-viewer-fullpage(:parentFolder="inventory.folderId" :fromModule="'inventory'")
</template>

<script>
import InventoryBuilderHeader from "@/components/purchase/inventory/InventoryBuilderHeader.vue";
import InventoryBuilder from "@/components/purchase/inventory/InventoryBuilder.vue";
import InventoryPdf from "@/components/purchase/inventory/InventoryPdf.vue";
import InventoryPreview from "@/components/purchase/inventory/InventoryPreview.vue";
import { mapGetters, mapActions } from "vuex";
import GedViewerFullpage from "@/components/ged/GedViewerFullpage";
import InternalNote from "@/components/purchase/inventory/internal-note/InternalNote.vue";

export default {
  name: "edit-inventory",
  ref: "edit-inventory",

  data() {
    return {
      editInventoryLoaded: false,
      previewKey: 0,
    };
  },
  props: {
    id: {
      default: null,
    },
    mode: {
      default: "inventory-edit",
    },
  },
  watch: {
    async $route(to, from) {
      this.$destroy();
    },
  },
  async created() {
    if (this.inventory.status == 3) {
      await this.changeInventoryTabActive("inventory-pdf");
    } else {
      await this.changeInventoryTabActive(this.mode);
    }

    if (!this.unitsList || this.unitsList.length == 0) {
      await this.getUnits({}).then((res) => {
        this.inventoryHeader.unitId.choice = res;
      });
    } else {
      this.inventoryHeader.unitId.choice = this.unitsList;
    }

    this.editInventoryLoaded = true;
  },
  computed: {
    ...mapGetters([
      "inventory",
      "isLoadingInventory",
      "isLoadingInventoryPDF",
      "isValidatingInventory",
      "inventoryTabActive",
      "unitsList",
    ]),
    inventoryHeader: {
      get() {
        return this.$store.getters.getInventoryHeader;
      },
      set(val) {
        this.$store.commit("setInventoryHeader", { ...inventoryHeader, val });
      },
    },
  },
  methods: {
    ...mapActions(["changeInventoryTabActive", "getUnits"]),
    changePreviewKey() {
      this.previewKey++;
    },
  },
  components: {
    InventoryBuilder,
    InventoryBuilderHeader,
    InventoryPreview,
    InventoryPdf,
    GedViewerFullpage,
    InternalNote,
  },
};
</script>

<style scoped>
#invoiceContainer {
  width: 98%;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}
.content-tab-fullpage {
  padding-top: 20px !important;
  background-color: white !important;
  height: 100%;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.preview)?_c('div',{on:{"mouseover":function($event){_vm.showTools = true},"mouseleave":function($event){_vm.showTools = false}}},[_c('div',{staticClass:"d-flex",class:_vm.edit ? 'justify-content-between' : ''},[_c('span',{staticClass:"text-primary font-weight-bold"},[_vm._v("Votre contact :")]),(_vm.edit && !_vm.disabled)?_c('span',{staticClass:"pr-1 cursor-pointer text-primary",on:{"click":_vm.saveUserInfo}},[_vm._v("Enregistrer")]):_vm._e(),(_vm.showTools && !_vm.edit && !_vm.disabled)?_c('span',{staticClass:"pl-1 cursor-pointer bold"},[_c('feather-icon',{attrs:{"icon":"Edit3Icon","size":"18"},on:{"click":function($event){_vm.edit=true}}})],1):_vm._e()]),(_vm.edit)?_c('div',[_c('div',{staticClass:"mb-1"},[_c('b-form-group',{staticClass:"pr-1",staticStyle:{"flex":"1"},attrs:{"label-for":"userId"}},[_c('validation-provider',{attrs:{"name":"userId","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"userId","loading":_vm.isLoadingUsersList,"clearable":false,"deselectFromDropdown":true,"closeOnSelect":true,"state":errors.length > 0 ? false : null,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.collaboratorsList,"get-option-label":function (collab) { return collab.lastName + ' ' + collab.firstName; },"reduce":function (elem) { return elem.userId; }},on:{"input":_vm.setUserValue},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [[_vm._v(" Aucun résultat trouvé")]]},proxy:true}],null,true),model:{value:(_vm.document.userId),callback:function ($$v) {_vm.$set(_vm.document, "userId", $$v)},expression:"document.userId"}})]}}],null,false,1441645474)})],1)],1),_c('div',{staticClass:"mb-1"},[_c('b-form-group',{staticClass:"pr-1",staticStyle:{"flex":"1"},attrs:{"label-for":"userMail"}},[_c('validation-provider',{attrs:{"name":"userMail"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Email"},model:{value:(_vm.document.userMail),callback:function ($$v) {_vm.$set(_vm.document, "userMail", $$v)},expression:"document.userMail"}})]}}],null,false,2086744482)})],1)],1),_c('div',{staticClass:"mb-1 d-flex"},[_c('div',{staticClass:"w-50"},[_c('b-form-group',{staticClass:"pr-1",staticStyle:{"flex":"1"},attrs:{"label-for":"userPhoneNumber"}},[_c('validation-provider',{attrs:{"name":"userPhoneNumber"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Téléphone principal"},model:{value:(_vm.document.userPhoneNumber),callback:function ($$v) {_vm.$set(_vm.document, "userPhoneNumber", $$v)},expression:"document.userPhoneNumber"}})]}}],null,false,2059417102)})],1)],1),_c('div',{staticClass:"w-50"},[_c('b-form-group',{staticClass:"pr-1",staticStyle:{"flex":"1"},attrs:{"label-for":"userSecondaryPhoneNumber"}},[_c('validation-provider',{attrs:{"name":"userSecondaryPhoneNumber"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Téléphone secondaire"},model:{value:(_vm.document.userSecondaryPhoneNumber),callback:function ($$v) {_vm.$set(_vm.document, "userSecondaryPhoneNumber", $$v)},expression:"document.userSecondaryPhoneNumber"}})]}}],null,false,2653973001)})],1)],1)])]):_c('div',[_c('div',[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.document.userFirstName)+" "+_vm._s(_vm.document.userLastName))])]),_c('div',[_c('span',[_vm._v(_vm._s(_vm.document.userMail ? "E-mail : " + _vm.document.userMail : ""))])]),_c('div',[_c('span',[_vm._v(_vm._s(_vm.document.userPhoneNumber ? "Tél : " + _vm.document.userPhoneNumber : ""))])]),_c('div',[_c('span',[_vm._v(_vm._s(_vm.document.userSecondaryPhoneNumber ? "Tél secondaire: " + _vm.document.userSecondaryPhoneNumber : ""))])])])]):_c('div',[_c('div',[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.document.userFirstName)+" "+_vm._s(_vm.document.userLastName))])]),_c('div',[_c('span',[_vm._v(_vm._s(_vm.document.userMail ? "E-mail : " + _vm.document.userMail : ""))])]),_c('div',[_c('span',[_vm._v(_vm._s(_vm.document.userPhoneNumber ? "Tél : " + _vm.document.userPhoneNumber : ""))])]),_c('div',[_c('span',[_vm._v(_vm._s(_vm.document.userSecondaryPhoneNumber ? "Tél secondaire: " + _vm.document.userSecondaryPhoneNumber : ""))])])])])}
var staticRenderFns = []

export { render, staticRenderFns }
<template lang="pug">
div.builder-header.px-2(v-show="!isLoadingInventory")
  //  && !isLoadingInventoryPDF && !isUpdatingInventoryDetail && !isValidatingInventory
  div
    ul#pills-tab.nav.nav-pill(role='tablist')
      li.nav-item(v-if="inventory.status < 3" @click="changeInventoryTabActive('inventory-edit')")
        div.nav-link.cursor-pointer.text-center(:class="inventoryTabActive == 'inventory-edit' ? 'active' : ''")
          feather-icon(icon="Edit2Icon" size="15") 
          |  Edition
      li.nav-item(v-if="checkIfPreviewIsPossible()" @click="showPreviewPdf")
        div.nav-link.cursor-pointer.text-center(:class="inventoryTabActive == 'inventory-pdf' ? 'active' : ''")
          feather-icon(icon="EyeIcon" size="15") 
          |  Prévisualisation
      li.nav-item(v-if="haveGedModule" @click="changeInventoryTabActive('attachments')")
        div.nav-link.cursor-pointer.text-center(:class="inventoryTabActive == 'attachments' ? 'active' : ''")
          feather-icon(icon="FolderIcon" size="15") 
          |  Documents
      li.nav-item(@click="changeInventoryTabActive('internal-note')")
        div.nav-link.cursor-pointer.text-center(:class="inventoryTabActive == 'internal-note' ? 'active' : ''")
          feather-icon(icon="LockIcon" size="15") 
          |  Notes
  div.d-flex.align-items-center.ml-auto
    .form-group.form-check.mb-0.mr-1.tab-control.builderControls(v-if="inventoryTabActive=='inventory-edit'" data-tab='inventory-edit')
      .d-flex.align-items-center
        b-dropdown.mr-1#dropdown-form(ref='dropdown' right='' variant='outline-secondary' text='Options')
          b-dropdown-form.dropdown-options
            h6.mt-1 Document 
            b-form-checkbox.checkbox-options(@input="saveAction(false)" v-model="inventory.hideUser")
                | Cacher le contact
        .vl
    div(v-if="inventory.status == 0")
      b-button.mr-1.btn-invoice-builder-header(@click="cancel" variant='outline-danger')
        | Supprimer
    div.d-flex.align-items-center.ml-auto(v-if="(inventory.status < 3) && inventoryDetails.length > 0")
      b-button-group
        b-dropdown.dropdown-options.pr-1(text='Finaliser le document' variant='success' dropup)
          b-dropdown-item.cursor-pointer(@click="finalizedInventory(1, false)" :disabled="inventory.status >= 2 " :style="inventory.status >= 2 ? 'opacity:0.5' : ''") 
            span.chip.justify-content-start
              span.point.bg-warning
              span.label.text-warning Passer en validation
          b-dropdown-item.cursor-pointer(@click="finalizedInventory(2, false)" :disabled="inventory.status >= 3" :style="inventory.status >= 3 ? 'opacity:0.5' : ''") 
            span.chip.justify-content-start
              span.point.bg-primary
              span.label.text-primary Valider l'inventaire
    div
      b-button-group(v-if="inventory.status < 3")
        b-button.btn-invoice-builder-header.m-0(@click="checkStatusBeforeSave(false)" variant='primary')
          | Enregistrer
        b-button.btn-invoice-builder-header.bl-white(@click="checkStatusBeforeSave(true)" style="border-left: 1px solid #ccc !important;" variant='primary')
          feather-icon(icon='LogOutIcon')
      b-button-group(v-else-if="inventory.path")
        b-button.btn-invoice-builder-header.m-0(variant='primary' @click="forceFileDownload(inventory.path)")
          | Télécharger
          feather-icon.ml-50(icon='DownloadIcon')
        b-button.btn-invoice-builder-header.bl-white(variant='primary' @click="cancel" style="border-left:1px solid #ccc !important")
          | Fermer 
          feather-icon.ml-50(icon='LogOutIcon')
      b-button.btn-invoice-builder-header(v-else variant='primary' @click="cancel" style="border-left:1px solid #ccc !important")
        | Fermer 
        feather-icon.ml-50(icon='LogOutIcon')
</template>

<script>
import {
  BButtonGroup,
  BButton,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import { checkFunctionUser } from "@/auth/utils.ts";

export default {
  computed: {
    ...mapGetters([
      "inventory",
      "inventoryTabActive",
      "isLoadingInventory",
      "isLoadingInventoryPDF",
      "isUpdatingInventoryDetail",
      "isValidatingInventory",
      "workspaceSelected",
    ]),
    inventory: {
      get() {
        return this.$store.getters.inventory;
      },
      set(value) {
        return this.$store.commit("SET_INVENTORY", value);
      },
    },
    inventoryDetails: {
      get() {
        return this.$store.getters.inventoryDetails;
      },
      set(value) {
        return this.$store.commit("SET_INVENTORY_DETAILS", value);
      },
    },
    haveGedModule() {
      return (
        this.checkFunctionUser(42, "all") && this.checkFunctionUser(43, "all")
      );
    },
  },
  methods: {
    ...mapActions([
      "changeInventoryTabActive",
      "updateInventory",
      "updateInventoryDetail",
      "deleteInventories",
    ]),
    checkFunctionUser,
    async finalizedInventory(action, exit) {
      if (action == 1) {
        await this.updateInventory({
          inventory: { ...this.inventory, status: 2 },
          updateState: true,
        });
        if (this.inventoryTabActive == "inventory-pdf") {
          this.$emit("changePreviewKey");
        } else {
          await this.changeInventoryTabActive("inventory-pdf");
        }
      } else if (action == 2) {
        this.$bvModal
          .msgBoxConfirm(
            "Attention, en clôturant votre inventaire, les quantités relevés seront mise à jour dans le stock informatique de vos produits.",
            {
              title: "Terminer l'inventaire",
              size: "sm",
              okVariant: "primary",
              okTitle: "Oui, confirmer",
              cancelTitle: "Annuler",
              cancelVariant: "outline-primary",
              hideHeaderClose: true,
              centered: true,
            }
          )
          .then(async (value) => {
            if (value) {
              this.$store.commit("SET_IS_VALIDATING_INVENTORY", true);
              await this.updateInventory({
                inventory: { ...this.inventory, status: 3 },
                updateState: true,
              })
                .then(async () => {
                  // Si la tab affiché correspond déjà à l'aperçu alor son rafrachit le composant pour que l'aperçu se regénère
                  if (this.inventoryTabActive == "inventory-pdf") {
                    this.$emit("changePreviewKey");
                  } else {
                    await this.changeInventoryTabActive("inventory-pdf");
                  }
                  this.$store.commit("SET_IS_VALIDATING_INVENTORY", false);
                })
                .catch((err) => {
                  console.error(err);
                  this.$store.commit("SET_IS_VALIDATING_INVENTORY", false);
                });
            }
          });
      }
    },
    showPreviewPdf() {
      this.$emit("changePreviewKey");
      this.$nextTick(() => {
        this.changeInventoryTabActive("inventory-pdf");
      });
    },
    saveAction(bool) {
      this.updateInventory({ inventory: this.inventory, updateState: bool });
    },
    async checkStatusBeforeSave(exit) {
      if (this.inventory.status == 0) {
        this.$store.getters.inventoriesList.unshift(this.inventory);
      }
      await this.updateInventory({
        inventory: {
          ...this.inventory,
          status: this.inventory.status == 0 ? 1 : this.inventory.status,
        },
        updateState: true,
      });
      if (exit) {
        this.$tabs.close({ to: "/stock/inventories" });
      }
    },
    checkIfPreviewIsPossible() {
      if (this.inventory.storageLocationId) {
        return true;
      } else {
        return false;
      }
    },
    forceFileDownload(donwload) {
      // TODO : ATTENTION FONCTIONNE PAS LOCAL, EXIGE CORS UNCLOCK
      axios({
        url: donwload,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/pdf" })
        );
        var fullUri = document.createElement("a");
        fullUri.href = fileURL;
        fullUri.setAttribute("download", this.inventory.documentReference);
        document.body.appendChild(fullUri);
        fullUri.click();
        URL.revokeObjectURL(fullUri.href);
      });
    },
    cancel() {
      if (this.inventory.status > 0) {
        this.closeTab();
      } else {
        this.$bvModal
          .msgBoxConfirm("Cette action est définitive et irréversible.", {
            title:
              "Êtes-vous sûr de vouloir quitter l'édition sans enregistrer ? L'inventaire en cours sera alors supprimé",
            size: "sm",
            okVariant: "primary",
            okTitle: "Oui",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          })
          .then((value) => {
            if (value) {
              if (this.inventory.status == 0) {
                this.deleteInventories({
                  inventoryIds: [this.inventory.id],
                }).then(() => {
                  this.closeTab();
                });
              }
            }
          });
      }
    },
    closeTab() {
      if (
        this.$route.params.routeOrigine &&
        this.$route.params.routeOrigine !== ""
      ) {
        this.$tabs.close({ to: "/" + this.$route.params.routeOrigine });
      } else {
        this.$tabs.close();
      }
    },
  },
  components: {
    BButtonGroup,
    BDropdown,
    BDropdownItem,
    BButton,
    BDropdownDivider,
  },
  directives: {
    Ripple,
  },
};
</script>

<style>
.vl {
  border-left: 1px solid #8a8e93;
  width: 1px;
  height: 16px;
  background: rgb(255, 255, 255);
}

.btn-invoice-builder-header {
  padding: 8px;
  height: 38px;
}

.bl-white {
  border-left-color: red !important;
}

.checkbox-options {
  margin-bottom: 10px;
}

.checkbox-options > label {
  font-size: 13px !important;
}
</style>

<template lang="pug">
  .quote-card(v-if="!preview")
    b-form-group(v-if="!inventory.storageLocation || editMode" label-for='customer')
      div.pb-50.d-flex
        span.text-primary.font-weight-bold Point de stockage :
      validation-provider(#default='{ errors }' name='Client')
        v-select#company(ref="selectStorageLocation" :loading='isLoadingStorageLocation' :state='errors.length > 0 ? false : null' :value='inventory.storageLocation ? inventory.storageLocation.label : ""' @input='setValue' :options='options' :placeholder='placeholder')
          template(v-slot:no-options='')
            template  Aucun r&eacute;sultat trouv&eacute; 
          li.border-bottom.p-1.py-50(slot='list-header')
            b-button.w-100.d-flex.justify-content-center.align-items-center(v-if="inventory.type==4" @click='newStorageLocation()' variant='primary' size='sm')
              | + Nouveau point de stockage
              span.link_icon.material-icons-outlined.text-white.m-0.ml-50(style='font-size:13px')
                | open_in_new
        small.text-danger {{ errors[0] }}
    div(v-else @mouseenter="showCardTool = true" @mouseleave="showCardTool = false")
      .card-info
        div.pb-50.d-flex.justify-content-between
          span.text-primary.font-weight-bold Point de stockage :
          .pr-0.cursor-pointer(v-if="showCardTool && !inventoryDetails")
              feather-icon(icon="Edit3Icon" size="18" v-b-tooltip.hover.top="'Modifier la fiche du point de stockage'" @click="editMode = true")
              feather-icon.ml-1(icon="XIcon" size="18" @click="resetValue()"  v-b-tooltip.hover.top="'Changer de point de stockage'")
        div(v-if="!editMode")
          span.font-weight-bold {{ inventory.storageLocation.label  }}
          div(v-if="inventory.storageLocation.address != null")
            span {{ inventory.storageLocation.address }}
          div(v-if="inventory.storageLocation.addressComplement != null")
            span {{ inventory.storageLocation.addressComplement }}
          div(v-if="inventory.storageLocation.zipCode != null || inventory.storageLocation.city != null")
            span {{ inventory.storageLocation.zipCode }} {{ inventory.storageLocation.city }}
  .quote-card(v-else)
    .card-info
      div.pb-50.d-flex
        span.text-primary.font-weight-bold Point de stockage :
      span.font-weight-bold {{ inventory.storageLocation.label  }}
      div(v-if="inventory.storageLocation.address != null")
        span {{ inventory.storageLocation.address }}
      div(v-if="inventory.storageLocation.addressComplement != null")
        span {{ inventory.storageLocation.addressComplement }}
      div(v-if="inventory.storageLocation.zipCode != null || inventory.storageLocation.city != null")
        span {{ inventory.storageLocation.zipCode }} {{ inventory.storageLocation.city }}
</template>

<script>
import vSelect from "vue-select";
import { VBTooltip } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
  localize,
} from "vee-validate";
import { required } from "@validations";
import { mapGetters, mapActions } from "vuex";

configure({
  generateMessage: localize("fr", {
    messages: {
      required: "Ce champ est requis",
    },
  }),
});
localize("fr");

export default {
  components: {
    "v-select": vSelect,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  props: {
    preview: {
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showCardTool: false,
      required,
      editMode: false,
      inventoryEdit: {},
    };
  },
  created() {
    if (!this.storageLocationsList || this.storageLocationsList.length == 0) {
      this.getStorageLocations({});
    }
  },
  computed: {
    ...mapGetters([
      "inventoryDetails",
      "isLoadingStorageLocation",
      "storageLocationsList",
      "companiesTypesList",
    ]),
    inventory: {
      get() {
        return this.$store.getters.inventory;
      },
      set(value) {
        return this.$store.commit("SET_INVENTORY", value);
      },
    },
  },
  methods: {
    ...mapActions(["getStorageLocations"]),
    setValue(value) {
      this.$emit("setValue", value);
      this.editMode = false;
    },
    resetValue() {
      let value = {
        id: null,
        label: null,
        storageLocationType: null,
        address: null,
        addressComplement: null,
        zipCode: null,
        city: null,
        latitude: null,
        longitude: null,
        phoneNumber: null,
        email: null,
      };
      this.setValue(value);
      this.editMode = true;
    },
    newStorageLocation() {
      this.$router.push({
        name: "new-storage-location",
        params: {
          routeOrigine: "/stock/new-inventory/" + this.inventory.id,
        },
      });
    },
  },
};
</script>

<style scoped>
#button-section {
  display: flex;
  justify-content: flex-end;
}
#button-section button {
  margin-left: 10px;
  width: 30em;
}
.card-info {
  padding: 10px;
  position: relative;
  background-color: #f1f1f1 !important;
  border: solid 2px #f1f1f1;
  border-radius: 3px;
}

.card-tools {
  position: absolute;
  right: 0;
  top: 0;
  /* background-color: white;  */
  padding: 10px;
  border-bottom: solid 2px #f1f1f1;
}

.card-tools > * {
  margin-right: 10px;
  cursor: pointer;
}
</style>

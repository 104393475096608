<template lang="pug">
div(style='display:contents')
  div(v-if="isLoadingInventory || isLoadingInventoryPDF || isUpdatingInventoryDetail || isValidatingInventory" class="h-100")
    .text-center.flex-center.h-100
        .loading-bg-inner
          .loader
            .outer
            .middle
            .inner
        .mt-5
          br
          br
          br
          |  {{ isValidatingInventory ? "Clôture de l'inventaire en cours, veuillez patienter..." : "Génération du PDF..." }}
  div(style='display:contents' v-show="!isLoadingInventoryPDF && !isValidatingInventory")
    iframe.pdfViewver(:src="inventoryPDF" style="width: 100%;height: 100%; border: solid 2px #f1f1f1; padding:0px") 
    div(style="display:contents" v-if="inventory.status < 3 || !inventory.path || inventory.path.includes('null.pdf')")
      inventoryPdfContent.pdfViewver(style="width: 100%;height: 100%; border: none; padding: 0px; display:none")
        table.structure
          thead
            tr
              td
                .page-header-space
          tbody
            tr
              td
                div#invoiceContainer.pdfViewver
                  InventoryPreviewHeader()
                  InventoryPreviewTable()
                  .watermark(v-if="inventory.status < 2")
                    .text PROVISOIRE
                  .watermark(v-else-if="(inventory.status == 2)")
                    .text A VALIDER
                  .watermark(v-else-if="inventory.status == 4")
                    .text ANNULÉ
          tfoot
            tr
              td
                .page-footer-space(:style="'height:'+pageFooterHeight()+'px !important'")
                  #pageFooter
          
</template>

<script>
import InventoryPdfContent from "@/components/purchase/inventory/InventoryPdfContent";
import InventoryPreviewHeader from "@/components/purchase/inventory/preview/InventoryPreviewHeader.vue";
import InventoryPreviewTable from "@/components/purchase/inventory/preview/InventoryPreviewTable.vue";
import { mapGetters } from "vuex";
import { statusToUpperCaseTranslate } from "@/types/api-orisis/library/TranslateOperations.ts";

export default {
  computed: {
    ...mapGetters([
      "inventory",
      "inventoryPDF",
      "isLoadingInventory",
      "isLoadingInventoryPDF",
      "isUpdatingInventoryDetail",
      "isValidatingInventory",
    ]),
    heightFooter() {
      return "100";
    },
  },
  components: {
    InventoryPdfContent,
    InventoryPreviewHeader,
    InventoryPreviewTable,
  },
  methods: {
    statusToUpperCaseTranslate,
    htmlEntities(str) {
      return String(str)
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;");
    },
    pageFooterHeight() {
      // var count = ((this.inventory.footer.match(/\<p/g) || []).length + (this.inventory.footer.match(/\<br/g) || []).length + (this.inventory.footer.match(/\<\/br/g) || []).length)*12;
      // if(count>50){
      //   return count
      // }else{
      //   return 50
      // }
      return 50;
    },
    decodeHtmlEntities(str) {
      var element = document.createElement("div");
      if (str && typeof str === "string") {
        // strip script/html tags
        str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, "");
        str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, "");
        element.innerHTML = str;
        str = element.textContent;
        element.textContent = "";
      }
      return str;
    },
  },
};
</script>

<template lang="pug">
tr
  td(v-for="(key, i) in Object.keys(inventoryHeader)" :key="i" v-if="inventoryHeader[key].display && (( inventory.status> 1 && line['quantityNoted']!==null) ||  inventory.status < 2)"  :class="inventoryHeader[key].class" :style="key =='quantityNoted' ? 'border-right: 2px #ccc solid !important; border-left: 2px #ccc solid !important;' : key =='quantity' ? 'border-left: 1px #ccc solid !important;' : ''" )
    div(v-if="inventoryHeader[key].inputType == 10") 
      SelectCol(:line="line" :dataKey="key" :invoiceHeader="inventoryHeader" :preview="true" :canEdit="false" :edit="false" :editFocus="false") 
    div(v-else)
      span(v-html="line[key]") 
    //- span test : {{  line[] }}
    //- div(v-if="inventoryHeader[key].inputType == 1") 
    //-   span {{inventoryHeader[key].prefix }} 
    //-   span {{inventoryHeader[key].numberToFixed ? formatNumber(line[key]) : line[key]}}
    //-   span &nbsp;{{inventoryHeader[key].unit}}
    //- div(v-else-if="inventoryHeader[key].inputType == 2") 
    //-   span {{formatNumberToString(line[key])}}
    //-   span &nbsp;{{inventoryHeader[key].unit}}
    //- div(v-else-if="inventoryHeader[key].inputType == 24") 
    //-   span(v-html="line[key]")
</template>

<script>
import { formatNumber, formatNumberToString } from "@/types/api-orisis/library/FormatOperations.ts"
import SelectCol from '@/components/invoice/builder/table/cols/SelectCol.vue'

export default {
  computed: {
    inventoryHeader: {
      get() {
        return this.$store.getters.getInventoryHeader;
      },
      set(val) {
        this.$store.commit("setinventoryHeader", {...inventoryHeader, val});
      }
    },
    inventory: {
      get() {
        return this.$store.getters.inventory;
      },
      set(value) {
        return this.$store.commit("SET_INVENTORY", value);
      }
    },
  },
  props: {
    line: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  methods: {
    formatNumber,
    formatNumberToString
  },
  components: {
    SelectCol
  }
}
</script>

<template lang="pug">
div(class="invoiceHeader")
  b-row
    b-col.col-sm-6(class="invoiceHeaderLeft")
      img.logo.mb-2(v-if="inventory.institutionLogo" :src='inventory.institutionLogo' :style="'width:' + institutionSelected.logoWidth + 'px !important;'")
      ul
        li.mb-2
          h2(:style="'color:'+inventory.institutionColor+' !important'" v-if="!inventory.institutionLogo") {{inventory.institutionName}}
          div {{inventory.institutionAddress}}
          div(v-if="inventory.institutionAddressComplement") {{inventory.institutionAddressComplement}}
          div(v-if="inventory.institutionZipCode || inventory.institutionCity") {{inventory.institutionZipCode}} {{inventory.institutionCity}}
        li(v-if="!inventory.hideUser").mt-2
          UserInfoDocument(:preview="true")
    b-col.col-sm-6(class="invoiceHeaderRight")
      ul(style="height:fit-content")
        li 
          h3(:style="'color:'+inventory.institutionColor+' !important'") Inventaire n° {{ inventory.documentReference ?  inventory.documentReference : 'en attente' }} 
            span(style='font-weight:300')
        li 
          SelectDate(keyValue="inventoryDate" @setValue="setInventoryDate" inventoryDetailKey="inventoryDate" text="En date du " style="margin-bottom:5px")
        li
          br(style="height:10px")
      div
        SelectStorageLocation(:preview="true")
  b-row
    b-col.mb-0(cols="12" class="align-items-center invoiceDescription" style="margin-top: 10px;")
      InventoryDescription(:key="'InvoiceDescription_'+inventory.id" :preview="true")
  v-style
    | table:after { background-color: {{inventory.institutionColor}} !important;}
</template>
<script>
import vSelect from "vue-select";
import { mapGetters, mapActions } from "vuex";

import SelectDate from "@/components/purchase/inventory/builder/SelectDate";
import UserInfoDocument from "@/components/purchase/inventory/builder/UserInfoDocument";
import SelectStorageLocation from "@/components/purchase/inventory/builder/SelectStorageLocation";
import InventoryDescription from "@/components/purchase/inventory/builder/InventoryDescription";

import {
  natureTranslate,
  natureSimplifiedTranslate,
} from "@/types/api-orisis/library/TranslateOperations.ts";

import { formatDate } from "@/types/api-orisis/library/FormatOperations.ts";
export default {
  data() {
    return {
      collaboratorInfo: null,
      options: [
        { label: "Heure", id: 0 },
        { label: "Jour", id: 1 },
        { label: "Semaine", id: 2 },
        { label: "Mois", id: 3 },
        { label: "Année", id: 4 },
      ],
    };
  },
  methods: {
    natureTranslate,
    natureSimplifiedTranslate,
    formatDate,

    setInventoryDate(date) {
      this.inventory = {
        ...this.inventory,
        inventoryDate: date,
      };
      this.saveAction();
    },
  },
  computed: {
    ...mapGetters([
      "institutionSelected",
      "companiesList",
      "affairsList",
      "natureList",
    ]),
    inventory: {
      get() {
        return this.$store.getters.inventory;
      },
      set(value) {
        return this.$store.commit("SET_INVENTORY", value);
      },
    },
  },
  components: {
    "v-select": vSelect,
    SelectDate,
    UserInfoDocument,
    SelectStorageLocation,
    InventoryDescription,
  },
};
</script>
<style scoped>
.align-items-center {
  align-items: center;
}

.quote-card {
  margin-bottom: 10px;
}

ul li {
  list-style: none;
}

.bold {
  font-weight: bold;
}

a {
  color: #0c3571 !important;
  text-decoration: underline !important;
}
</style>
